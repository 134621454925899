<template>
  <div class="cmp-product-price" v-if="showPrices" :class="parentClassRef">
    
    <!-- BESPOKE NOX: Add price per piece -->
    <template v-if="showPricePerPiece">
      <ul class="price-list flex-row-nowrap align-items-baseline">
        <li class="default">
          <span>{{ prices.formattedPricePerPiece }}</span>
        </li>
      </ul>
    </template>
    <!-- END BESPOKE NOX -->
    
    <template v-else>
      <ul class="price-list flex-row-nowrap align-items-baseline">
        <li class="default">
          <span>{{ prices.price }}</span>
        </li>
        <li v-if="prices.isSalesAction" class="original salesaction">
          <span>{{ prices.basePrice }}</span>
        </li>
        <!-- BESPOKE NOX-71: Added per 100 label -->
        <li class="per-100">
          <span v-translation="{ type: 'label', code: 'lbl_per_100' }"></span>
        </li>
        <!-- END BESPOKE NOX-71 -->
      </ul>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false },
    // BESPOKE
    showPricePerPiece: { type: Boolean, default: false }
    // END BESPOKE
  },
  computed: {
    ...mapGetters(['showPrices'])
  }
}
</script>

<style>

</style>
